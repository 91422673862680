<template>
  <div id="app" class="app-wrapper">

    <editor
      ref="editor"
      :isPreviewBtn="isPreview"
      :value="editAmisSchema"
      theme="antd"
      @onChange="onChange"
      @submit="submitHandle"
      @cancel="cancelHandle"
      @synAutoSaveInfo="synAutoSaveInfoHandle"></editor>
    <!-- antd -->
    <a-modal v-model="selectVisible" title="选择同步菜单内容" @ok="handleOk">
      <a-select
        ref="select"
        v-model="selectMenuId"
        placeholder="请选择同步菜单内容"
        style="width: 100%;"
      >
        <a-select-option :value="item.menuId" v-for="item in selectList" :key="item.menuId">{{ item.name }}</a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import AmisDemo from '@/components/amis-demo/AmisDemo.vue'
import Editor from '@/components/amis/AmisEditor.vue'
import { getMenuContent, updateMenuContent } from '@/api/menu'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import storage from 'store'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    AmisDemo,
    Editor
  },
  data() {
    return {
      editorProps: {},
      isPreview: true,
      editorJson: '',
      value: null,
      menuId: '',
      selectVisible: false,
      selectList: [],
      selectMenuId: null,
      token: encodeURIComponent('Bearer ' + storage.get(ACCESS_TOKEN))
    }
  },
  computed: {
    ...mapGetters(['permissions'])
  },
  created() {
    this.menuId = this.$route.query.menuId
    console.log('permissions>>>>>', this.permissions)

    this.getMenuContent(this.menuId)
    this.$nextTick(() => {
      setTimeout(() => {
        console.log('editAmisSchema>>>>>11>>>>', this.editAmisSchema)
      }, 2000)
    })

    // this.value = this.editAmisSchema
  },
  methods: {
    getEditorJson() {
      // this.editorJson = this.$refs.editor.getSchema()

    },
    getMenuContent(menuId) {
      getMenuContent({ menuId }).then(response => {
        console.log('当前amis菜单内容>>>>', response)
        console.log('当前amis菜单内容>>>>permissions>>>>', this.permissions)
        const schema = response.data ? JSON.parse(response.data) : {}
        if (!schema['data']) {
          schema['data'] = {
            permissions: this.permissions,
            token: storage.get(ACCESS_TOKEN),
            locationUrl: window.location.origin,
            baseURL: process.env.VUE_APP_BASE_API
          }
        } else {
          schema.data['permissions'] = this.permissions

          schema.data['token'] = storage.get(ACCESS_TOKEN)

          schema.data['locationUrl'] = window.location.origin
          schema.data['baseURL'] = process.env.VUE_APP_BASE_API
        }

        this.$store.commit('SET_EDITAMISSCHEMA', schema)
      })
    },
    onChange(e) {
      console.log('编辑器内容>>>', e)
      this.editorJson = e
    },
    // 同步自动保存内容
    synAutoSaveInfoHandle(menuId) {
      let oldMenuInfo = localStorage.getItem('iot_amis_menu_info')
      let oldMenuList = localStorage.getItem('iot_amis_menu_list')
      if (oldMenuInfo) {
        oldMenuInfo = JSON.parse(oldMenuInfo)
      }

      if (oldMenuList) {
        oldMenuList = JSON.parse(oldMenuList)
        this.selectList = []
        for (const key in oldMenuList) {
          const selectItem = {
            menuId: key,
            name: oldMenuList[key]
          }
          this.selectList.push(selectItem)
        }
      }
      this.selectMenuId = menuId
      this.selectVisible = true
      console.log('同步自动保存内容>>>>oldMenuInfo>>>>', oldMenuInfo)
      console.log('同步自动保存内容>>>>oldMenuList>>>>', oldMenuList)
    },
    // 同步
    handleOk() {
      this.selectVisible = false
      let oldMenuInfo = localStorage.getItem('iot_amis_menu_info')
      if (oldMenuInfo) {
        oldMenuInfo = JSON.parse(oldMenuInfo) || {}
      }

      this.$store.commit('SET_EDITAMISSCHEMA', oldMenuInfo[this.selectMenuId])
    },
    // 保存
    submitHandle() {
      if (!this.editorJson) {
        // "data":{
        //   "permissions":this.permissions
        // },

        // if (!this.editAmisSchema['data']) {
        //   this.editAmisSchema['data'] = {
        //     permissions: this.permissions
        //     // token: 'Bearer ' + token
        //   }
        // } else {
        //   if (!this.editAmisSchema.data['permissions']) {
        //     this.editAmisSchema.data['permissions'] = this.permissions
        //   }

        //   // if (!this.editAmisSchema.data['token']) {
        //   //   this.editAmisSchema.data['token'] = 'Bearer ' + token
        //   // }
        // }
        this.editorJson = JSON.parse(JSON.stringify(this.editAmisSchema))
      }

      console.log('保存>>>', this.editorJson)

      this.$store.commit('SET_SAVEAMISSCHEMA', this.editorJson)
      this.saveMenuContent(JSON.stringify(this.editorJson))

      // location.go(-1)
    },

    // 保存当前菜单内容
    saveMenuContent(pageContent) {
      updateMenuContent({
          'menuId': this.menuId,
          'pageContent': pageContent
      }).then(response => {
        console.log('保存当前菜单内容>>>>', response)
        this.$message.success(
                '保存成功',
                3
              )
        // setTimeout(() => {
        //   this.cancelHandle()
        // }, 2000)
        }
      )
    },
    cancelHandle() {
      // window.history.go(-1)
      window.close()
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.app-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
