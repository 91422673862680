<template>
  <div class="amis">
    <div class="amis-header">
      <div class="amis-header-title">可视化编辑器</div>
      <div class="amis-header-btn">
        <a-switch v-model="autoSaveState" checked-children="开启自动保存" un-checked-children="关闭自动保存" @change="switchChange" style="margin-right: 10px;"/>
        <a-button size="small" v-if="!autoSaveState" style="margin: 0 10px;" @click="$emit('synAutoSaveInfo',menuId)">同步自动保存内容</a-button>
        <a-button size="small" v-if="isPreviewBtn&&!isPreview" @click="setIsPreview">预览</a-button>
        <a-button size="small" type="primary" v-if="isPreviewBtn&&isPreview" @click="setIsPreview">编辑</a-button>
        <a-button size="small" style="margin: 0 10px;" @click="$emit('cancel')">关闭</a-button>
        <!-- <a-button size="small" type="primary" @click="saveMenuContent">保存</a-button> -->
        <a-button size="small" type="primary" @click="$emit('submit')">保存</a-button>
      </div>
    </div>
    <div class="amis-editor">
      <amis-editor
        id="editorName"
        :key="refreshKey"
        className="is-fixed"
        :locale="lang"
        :is-mobile="isMobile"
        :preview="isPreview"
        :theme="'antd'"
        :amisEnv="env"
        :value="value"
        @onChange="onChange"
      />
    </div>
    <!-- :theme="theme" -->

  </div>
</template>
<script>
import 'font-awesome/css/font-awesome.css'
import 'bootstrap/dist/css/bootstrap.css'
// import 'amis-editor/dist/style.css'
import 'amis-editor-core/lib/style.css'
import 'amis-theme-editor/lib/style.css'
import 'amis-theme-editor/lib/renderers.css'
import 'amis-ui/lib/helper.css'
// import 'amis-ui/scss/helper.scss'
// import 'amis-ui/scss/helper.scss'
import 'amis-ui/lib/index.js'
import 'amis-ui/lib/locale/en-US'

import 'amis/lib/helper.css'
import 'amis/sdk/iconfont.css'
import 'amis/lib/themes/antd.css'
import 'amis/lib/themes/default.css'
import 'amis/lib/themes/dark.css'
import 'amis/lib/themes/cxd.css'
import 'amis/sdk/sdk.js'
import { Editor } from 'amis-editor'
import { ReactInVue } from 'vuera'
import { getQuery } from '@/utils/util'

import {
    toast,
    alert,
    confirm
} from 'amis'
import copy from 'copy-to-clipboard'
import * as qs from 'qs'
import axios from 'axios'
import request from '@/utils/request'
import { mapGetters } from 'vuex'
export default {
  name: 'Editor',
  components: {
    amisEditor: ReactInVue(Editor)
  },
  props: {
    isPreviewBtn: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'default'
    },
    value: {
      type: Object,
      default: function () {
        return {
          isPreview: false,
          message: 'hello'
        }
      }
    }
  },
  data() {
    return {
      isPreview: false,
      refreshKey: Math.random() * (3 - 1),
      schema: {},
      autoSaveState: false,
      autoSaveTime: null,
      menuId: null,
      title: '',
      autoSaveInfo: null
    }
  },
  computed: {
    ...mapGetters(['lang'])
  },
  mounted() {
    this.schema = this.value
  },
  created() {
    document.title = getQuery('name') + '-编辑器'
    this.menuId = getQuery('menuId')
    this.title = getQuery('name')
    this.initEnv()
    // this.autoSaveState = !!localStorage.getItem('iot_amis_auto_save_state') || false
    this.autoSaveState = false
    this.autoSaveInfo = this.editAmisSchema
    console.log('editAmisSchema>>>>>', this.editAmisSchema)
  },
  beforeDestroy() {
    if (this.autoSaveTime) {
      clearInterval(this.autoSaveTime)
      this.autoSaveTime = null
    }
  },
  methods: {
    // 监听自动保存开关
    switchChange(e) {
      console.log('监听自动保存开关>>>>>', e)
      console.log('监听自动保存开关>>>>>autoSaveState>>>>>', this.autoSaveState)
      localStorage.setItem('iot_amis_auto_save_state', this.autoSaveState)

      if (this.autoSaveTime) {
        clearInterval(this.autoSaveTime)
        this.autoSaveTime = null
      }

      if (this.autoSaveState) {
        this.autoSaveTime = setInterval(() => {
          console.log('localStorage>>>>>', localStorage.getItem('iot_amis_menu_info'))

          let oldMenuInfo = localStorage.getItem('iot_amis_menu_info')
          let oldMenuList = localStorage.getItem('iot_amis_menu_list')
          try {
            oldMenuInfo = JSON.parse(oldMenuInfo) || {}
            oldMenuList = JSON.parse(oldMenuList) || {}
          } catch (error) {
            oldMenuInfo = {}
            oldMenuList = {}
          }
          console.log('oldMenuInfo>>>>>', oldMenuInfo)
          console.log('oldMenuList>>>>>', oldMenuList)
          console.log('editAmisSchema>>>>>', this.autoSaveInfo)

          oldMenuInfo[this.menuId] = this.autoSaveInfo
          oldMenuList[this.menuId] = this.title
          localStorage.setItem('iot_amis_menu_info', JSON.stringify(oldMenuInfo))
          localStorage.setItem('iot_amis_menu_list', JSON.stringify(oldMenuList))
        }, 1000)
      }
    },
    setIsPreview() {
      this.isPreview = !this.isPreview
    },
    setSchema(obj) {
      console.log(obj)
      this.schema = obj
    },
    getSchema() {
      console.log('233', this.schema)
      return this.schema
    },
    onChange(e) {
      console.log(JSON.stringify(e))
      this.autoSaveInfo = e
      this.$emit('onChange', e)
    },

    initEnv () {
      this.env = {
          enableAMISDebug: false,
          session: 'global',
          updateLocation: this.updateLocation || this.updateRoute,
          isCurrentUrl: (to) => {
              const link = this.normalizeLink(to)
              const location = window.location
              let pathname = link
              let search = ''
              const idx = link.indexOf('?')
              if (~idx) {
                  pathname = link.substring(0, idx)
                  search = link.substring(idx)
              }
              if (search) {
                  if (pathname !== location.pathname || !location.search) {
                      return false
                  }
                  const query = qs.parse(search.substring(1))
                  const currentQuery = qs.parse(location.search.substring(1))
                  return Object.keys(query).every(key => query[key] === currentQuery[key])
              } else if (pathname === location.pathname) {
                  return true
              }
              return false
          },
          fetcher: ({
              url,
              method,
              data,
              config,
              headers
          }) => {
              console.log('method>>>>', method)
              console.log('config>>>>', config)
              console.log('data>>>>', data)
              config.method = method
              const requestObj = {
                  url: url,
                  method: method,
                  headers: headers,
                  ...config
              }
              console.log('requestObj>>>', requestObj)

              if (method === 'get' && data) {
                  requestObj['params'] = data
              } else {
                  requestObj['data'] = data
              }

              console.log('requestObj>>>', requestObj)

              return request(requestObj)
          },
          isCancel: (e) => axios.isCancel(e),
          alert,
          notify: (type, msg) => {
              toast[type] ? toast[type](msg, type === 'error' ? '系统错误' : '系统消息') : console.warn('[Notify]', type, msg)
              console.log('[notify]', type, msg)
          },
          confirm,
          copy: (contents, options = {}) => {
              const ret = copy(contents, options)
              ret && (!options || options.shutup !== true) && toast.info('内容已拷贝到剪切板')
              return ret
          }
      }
  }
  }
}
</script>

<style lang="less" scoped>
.amis {
  width: 100%;
  // height: calc(100vh - 60px * 2.7) !important;
  height: 100vh !important;
  position: relative;
  display: flex;
  flex-direction: column;
  #editor {
    width: 100%;
    height: calc(100vh - 60px * 2.7) !important;
    // height: 100%;
  }

  .amis-header {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #e8e9eb;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-flex: 0 0 48px;
    -ms-flex: 0 0 48px;
    flex: 0 0 48px;
    padding: 0 20px;
    z-index: 1000;
    display: flex;

    .amis-header-title{
      display: flex;
      align-items: center;
      font-family: PingFangSC-Medium;
      font-size: 16px;
      font-weight: 500;
    }

    .amis-header-btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .amis-editor {
    flex: 1;
    position: relative;
  }
}

.is-fixed {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.ae-Editor .ae-Editor-inner {
  height: calc(100vh - 60px * 2.7) !important;
}
</style>
